























































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import {
    IUserProfile,
    IPartnerUpdate,
    IBrandUpdate,
} from '@/interfaces';
import {
    readPartners,
    readCategories,
    readAffiBrands,
    readImpersonated,
} from '@/store/affi/getters';
import {
    commitSetPartners,
} from '@/store/affi/mutations';
import {
    dispatchGetPartners,
    dispatchUpdatePartnerBulk,
    dispatchGetCategories,
    dispatchGetBrands,
    dispatchUpdateBrand,
} from '@/store/affi/actions';
import { readHasAdminAccess } from '@/store/main/getters';

import { ttt } from '@/nutils';

interface IPagination {
  rowsPerPage: number;
  totalItems: number;
  page: number;
};

@Component
export default class Partners extends Vue {
  public ttt = ttt;
  public selected: number[] = [];
  public pagination: IPagination = { rowsPerPage: 10, totalItems: 0, page: 1, };
  public dirty: number[] = [];
  public search: string = '';

  _headers = [
    { text: ttt('Move'), sortable: false, hidden: true },
    { text: '', sortable: false, value: 'brand_logo', align: 'left', },
    { text: ttt('Name'), sortable: false, value: 'shop_name', align: 'left', },
    { text: ttt('Categories'), sortable: false, value: 'categories', align: 'left', },
    { text: ttt('Date Added'), sortable: false, value: 'registered_at', align: 'left', },
    // { text: ttt('Recommended'), sortable: false, value: 'analytical_orders', align: 'left', },
    { text: ttt('Active'), sortable: false, value: 'pp_partner_status', align: 'center', },
  ];
  public headers = this._headers.filter( h => this.hasAdminAccess || !h.hidden );

  get pages() {
    if (this.pagination.totalItems === 0)
        this.pagination.totalItems = this.partners.length;
    return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
  }

  get partners() {
    return readPartners(this.$store);
  }

  get categories() {
    return readCategories(this.$store);
  }

  get brand() {
    const imp = readImpersonated(this.$store);
    const bs = readAffiBrands(this.$store)
        .filter( (obj) => ( imp === -1 || obj && obj.owner_id === imp ) );
    return bs[0];
  }

  get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public renderCategories(catIdList: string): string {
    const ids = (catIdList || '').split(',');
    return this.categories
        .filter( (e) => ids.includes( e.id.toString() ) )
        .map( (e) => e.title )
        .join();
  }

  public async mounted() {
    const imp = readImpersonated(this.$store);

    await dispatchGetPartners(this.$store, imp);
    await dispatchGetCategories(this.$store);

    const parts = readPartners(this.$store)
        .map( (p) => {
            p.categories = this.renderCategories(p.categories);
            return p;
        });
    commitSetPartners(this.$store, parts);

    await dispatchGetBrands(this.$store);
    this.dirty = [];
  }

  public async submit() {
    // console.log(this.dirty);
    const updList: { id: number, partner: IPartnerUpdate }[] = [];
    let i=0;
    for (const e of this.partners) {
      if (e.position_order !== i || this.dirty.includes(e.id)) {
        const updatedPartner: IPartnerUpdate = {
          pp_partner_status: e.pp_partner_status,
          pp_brand_id: e.pp_brand_id,
          pp_partner_id: e.pp_partner_id,
          pp_partner_request: e.pp_partner_request,
          pp_partner_accept: e.pp_partner_accept,
          position_order: i,
        };
        updList.push( { id: e.id, partner: updatedPartner } );
      }
      i+=1;
    };
    // update status of brand
    const updBrand: IBrandUpdate = { has_choose_partners: true };

    await Promise.all([
        dispatchUpdateBrand(this.$store, { id: this.brand!.id, brand: updBrand }),
        dispatchUpdatePartnerBulk(this.$store, updList)
    ]);

    this.$router.push('/main/dashboard');
  }

  public cancel() {
    this.$router.back();
  }
  public rowClicked(item) {
    // this.selected = [item.id];
  }
  public reset() {
    // this.selected = [];
    this.dirty = [];
  }

  public openwindow(url) {
    window.open(url, '_blank');
  }
  public movetop(item) {
    const idx = this.partners.findIndex((e) => e.id === item.id);
    if (idx <= 0) return;
    this.arraymove(this.partners, idx, 0);
  }
  public moveup(item) {
    const idx = this.partners.findIndex((e) => e.id === item.id);
    if (idx <= 0) return;
    this.arraymove(this.partners, idx, idx-1);
  }
  public movedown(item) {
    const idx = this.partners.findIndex((e) => e.id === item.id);
    if (idx >= (this.partners.length-1)) return;
    this.arraymove(this.partners, idx, idx+1);
  }
  public arraymove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  public orderby_analytical_orders() {
    this.partners.sort((e1,e2) => e1.analytical_orders - e2.analytical_orders);
  }
  public orderby_registered_at() {
    this.partners.sort((e1,e2) => {
      if (e1.registered_at > e2.registered_at) { return 1; }
      if (e1.registered_at < e2.registered_at) { return -1; }
      return 0;
    });
  }
  public orderby_pp_partner_status() {
    this.partners.sort((e1,e2) => {
      if (e1.pp_partner_status) return 1;
      else return -1;
    });
  }
  public toggled(item) {
    // console.log(item);
    this.dirty.push(item.id);
  }
}
